import Vue from "vue";
import axios from "axios";
import store from "../store";
import router from "../router";
// Vue.prototype.$baseURL = "https://martofworld.onrender.com/";
Vue.prototype.$baseURL = "https://api.martofworld.net/";

axios.defaults.baseURL = `${Vue.prototype.$baseURL}api`;

window.axios = axios;
Vue.prototype.$axios = axios;

axios.defaults.headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};


let ErrorTypes = {
  401: "You need to login first",
  403: "You don't have access to read this data",
  500: 'Error in server will be fixed soon',
}

export default async () => {
  // show loader in each request
  window.axios.interceptors.request.use(
    (confiq) => {
      if (confiq.method != "get" && !confiq.params && confiq.url != "products/search") {
        store.dispatch("loading", true);
      }
      return confiq;
    },
    (error) => {
      return error;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      store.dispatch("loading", false);
      return response;
    },
    async function (error) {
      const isUser = router.history.current.fullPath.indexOf('user') > -1;
      const isAdmin = router.history.current.fullPath.indexOf('admin') > -1;

      if (isUser && (error.response.status == 401 || error.response.status == 403)) {
        // store.dispatch('logout')
      }

      if (isAdmin && (error.response.status == 401 || error.response.status == 403)) {
        // store.dispatch('logoutAdmin')
      }
      
      store.dispatch("showSnack", {
        text: ErrorTypes[error.response.status] || error.response.data.message || 'Something going wrong...',
        color: "error",
      });
      // }
      store.dispatch("loading", false);
      return error.response.data;
    }
  );
};
