<template>
  <div class="base-input input-style-textarea  dashboard-input">
    <v-textarea
      outlined
      :placeholder="placeholder"
      dense
      max
      :rows="rows"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      @input="$emit('input', $event)"
      :readonly="readOnly"
      :append-icon="isSearch ? 'mdi-magnify' : ''"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
  },
};
</script>

<style lang="scss">
.input-style-textarea {
  .v-input__slot {
    fieldset {
      border: 0px;
      height: 100% !important;
      background: #e5ebffab;
    }

    .v-input__append-inner {
      margin-top: 5px !important;
    }
    .v-text-field__slot {
      height: 100% !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        border: 1px solid #e44358 !important;
      }
    }
  }
}
</style>
