
const routes = [
    {
        path: "/admin/login",
        name: "Admin-login",
        meta: {
            requiresNoAuth: true,
            title: "Admin-login",
        },
        component: () => import("../views/admin-dashboard/pages/login/Index.vue"),
    },
    {
        path: "/admin",
        component: () => import("../layout/admin/Sidebar.vue"),
        meta: {
            requiresAuth: true,
            title: "Admin-login",
        },
        children: [
            {
                path: "/",
                name: "Admin-overview",
                meta: {
                    requiresAuth: true,
                    title: "Admin-overview",
                },
                component: () => import("../views/admin-dashboard/pages/overview/Index.vue"),
            },
            {
                path: "/admin/company-attributes/categories",
                name: "Admin-company-categories",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-categories",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/categories/Index.vue"),
            },
            {
                path: "/admin/company-attributes/categories/sub-categories/:id",
                name: "Admin-company-sub-categories",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-sub-categories",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/categories/sub-categories/Index.vue"),
            },
            {
                path: "/admin/company-attributes/memberships",
                name: "Admin-company-memberships",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-memberships",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/memberships/Index.vue"),
            },
            {
                path: "/admin/company-attributes/memberships/add",
                name: "Admin-add-company-memberships",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-memberships",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/memberships/add/Index.vue"),
            },
            {
                path: "/admin/company-attributes/memberships/:id",
                name: "Admin-edit-company-memberships",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-memberships",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/memberships/add/Index.vue"),
            },
            {
                path: "/admin/company-attributes/request-type",
                name: "Admin-company-request",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-request",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/request-type/Index.vue"),
            },
            {
                path: "/admin/company-attributes/segment",
                name: "Admin-company-segment",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-segment",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/segment/Index.vue"),
            },
            {
                path: "/admin/company-attributes/type",
                name: "Admin-company-type",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-type",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/type/Index.vue"),
            },
            {
                path: "/admin/company-attributes/tags",
                name: "Admin-company-tags",
                meta: {
                    requiresAuth: true,
                    title: "Admin-company-tags",
                },
                component: () => import("../views/admin-dashboard/pages/company-attributes/tags/Index.vue"),
            },
            {
                path: "/admin/requests",
                name: "Admin-requests",
                meta: {
                    requiresAuth: true,
                    title: "Admin-request",
                },
                component: () => import("../views/admin-dashboard/pages/requests/Index.vue"),
            },
            {
                path: "/admin/notifications",
                name: "Admin-notifications",
                meta: {
                    requiresAuth: true,
                    title: "Admin-notifications",
                },
                component: () => import("../views/admin-dashboard/pages/notifications/Index.vue"),
            },
            {
                path: "/admin/contact-messages",
                name: "Contact-messages",
                meta: {
                    requiresAuth: true,
                    title: "Contact-messages",
                },
                component: () => import("../views/admin-dashboard/pages/contact-messages/Index.vue"),
            },
            {
                path: "/admin/requests/add",
                name: "Admin-add-requests",
                meta: {
                    requiresAuth: true,
                    title: "Admin-add-requests",
                },
                component: () => import("../views/admin-dashboard/pages/requests/add/Index.vue"),
            },
            {
                path: "/admin/requests/:id",
                name: "Admin-edit-requests",
                meta: {
                    requiresAuth: true,
                    title: "Admin-requests",
                },
                component: () => import("../views/admin-dashboard/pages/requests/add/Index.vue"),
            },
            {
                path: "/admin/companies",
                name: "Admin-companies",
                meta: {
                    requiresAuth: true,
                    title: "Admin-companies",
                },
                component: () => import("../views/admin-dashboard/pages/companies/Index.vue"),
            },
            {
                path: "/admin/companies/add",
                name: "Admin-add-companies",
                meta: {
                    requiresAuth: true,
                    title: "Admin-add-companies",
                },
                component: () => import("../views/admin-dashboard/pages/companies/add/Index.vue"),
            },
            {
                path: "/admin/companies/:id",
                name: "Admin-edit-companies",
                meta: {
                    requiresAuth: true,
                    title: "Admin-companies",
                },
                component: () => import("../views/admin-dashboard/pages/companies/add/Index.vue"),
            },
            {
                path: "/admin/exhibitions",
                name: "Admin-exhibitions",
                meta: {
                    requiresAuth: true,
                    title: "Admin-exhibitions",
                },
                component: () => import("../views/admin-dashboard/pages/exhibitions/Index.vue"),
            },
            {
                path: "/admin/memberships",
                name: "Admin-memberships",
                meta: {
                    requiresAuth: true,
                    title: "Admin-memberships",
                },
                component: () => import("../views/admin-dashboard/pages/memberships/Index.vue"),
            },
            {
                path: "/admin/products",
                name: "Admin-products",
                meta: {
                    requiresAuth: true,
                    title: "Admin-products",
                },
                component: () => import("../views/admin-dashboard/pages/products/Index.vue"),
            },
            {
                path: "/admin/blogs",
                name: "Admin-blogs",
                meta: {
                    requiresAuth: true,
                    title: "Admin-blogs",
                },
                component: () => import("../views/admin-dashboard/pages/blogs/Index.vue"),
            },
            {
                path: "/admin/blogs/add",
                name: "Admin-add-blogs",
                meta: {
                    requiresAuth: true,
                    title: "Admin-add-blogs",
                },
                component: () => import("../views/admin-dashboard/pages/blogs/add/Index.vue"),
            },
            {
                path: "/admin/blogs/:id",
                name: "Admin-edit-blogs",
                meta: {
                    requiresAuth: true,
                    title: "Admin-blogs",
                },
                component: () => import("../views/admin-dashboard/pages/blogs/add/Index.vue"),
            },
            {
                path: "/admin/admins",
                name: "Admin-admins",
                meta: {
                    requiresAuth: true,
                    title: "Admin-admins",
                },
                component: () => import("../views/admin-dashboard/pages/admins/Index.vue"),
            },
            {
                path: "/admin/general-pages/categories",
                name: "Admin-page-categories",
                meta: {
                    requiresAuth: true,
                    title: "Admin-page-categories",
                },
                component: () => import("../views/admin-dashboard/pages/general-pages/categories/Index.vue"),
            },
            {
                path: "/admin/general-pages/pages",
                name: "Admin-general-pages-pages",
                meta: {
                    requiresAuth: true,
                    title: "Admin-general-pages-pages",
                },
                component: () => import("../views/admin-dashboard/pages/general-pages/pages/Index.vue"),
            },
            {
                path: "/admin/general-pages/pages/add",
                name: "Admin-general-pages-pages-add",
                meta: {
                    requiresAuth: true,
                    title: "Admin-general-pages-pages-add",
                },
                component: () => import("../views/admin-dashboard/pages/general-pages/pages/add/Index.vue"),
            },
            {
                path: "/admin/general-pages/pages/:id",
                name: "Admin-general-pages-pages-edit",
                meta: {
                    requiresAuth: true,
                    title: "Admin-general-pages-pages-edit",
                },
                component: () => import("../views/admin-dashboard/pages/general-pages/pages/add/Index.vue"),
            },

            {
                path: "/admin/main-page/sliders",
                name: "Admin-main-page-sliders",
                meta: {
                    requiresAuth: true,
                    title: "Admin-main-page-sliders",
                },
                component: () => import("../views/admin-dashboard/pages/main-page/sliders/Index.vue"),
            },
            {
                path: "/admin/main-page/settings",
                name: "Admin-main-page-settings",
                meta: {
                    requiresAuth: true,
                    title: "Admin-main-page-settings",
                },
                component: () => import("../views/admin-dashboard/pages/main-page/settings/Index.vue"),
            },
            {
                path: "/admin/main-page/logo-slider",
                name: "Admin-main-page-logo-slider",
                meta: {
                    requiresAuth: true,
                    title: "Admin-main-page-logo-slider",
                },
                component: () => import("../views/admin-dashboard/pages/main-page/logo-slider/Index.vue"),
            },
            {
                path: "/admin/verified-company",
                name: "Admin-verified-company",
                meta: {
                    requiresAuth: true,
                    title: "Admin-verified-company",
                },
                component: () => import("../views/admin-dashboard/pages/verified-company/Index.vue"),
            },
            {
                path: "/admin/verify-elements",
                name: "Admin-verify-elements",
                meta: {
                    requiresAuth: true,
                    title: "Admin-verify-elements",
                },
                component: () => import("../views/admin-dashboard/pages/verify-elements/Index.vue"),
            },
        ],
    }];

export default routes