import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  let isLoginAdmin = localStorage.getItem("adminToken");
  let isLoginUser = localStorage.getItem("token");

  let pagesType = ''
  // role 
  if (to.matched.some(record => record.meta.title && record.meta.title.indexOf('Admin') > -1)) {
    pagesType = 'admin'
    if (to.matched.some((record) => record.meta.requiresNoAuth)) {
      if (isLoginAdmin) {
        next({
          path: "/" + pagesType,
        });
      } else {
        next();
      }
      return;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // if not, redirect to login page.
      if (isLoginAdmin) {
        next();
      } else {
        next({
          path: "/" + pagesType + "/login",
        });
      }
      return;
    }
  }
  if (to.matched.some(record => record.meta.title && record.meta.title.indexOf('User') > -1)) {
    pagesType = 'user'
    if (to.matched.some((record) => record.meta.requiresNoAuth)) {
      if (isLoginUser) {
        next({
          path: "/" + pagesType,
        });
      } else {
        next();
      }
      return;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // if not, redirect to login page.
      if (isLoginUser) {
        next();
      } else {
        next({
          path: "/" + pagesType + "/login",
        });
      }
      return;
    }
  }
  next()

});

export default router;
