
const routes = [
    {
        path: "/",
        component: () => import("../layout/website/Main.vue"),
        meta:{},
        children: [
            {
                path: "/",
                name: "Homepage",
                meta: {
                    title: "Homepage",
                },
                component: () => import("../views/website/pages/homepage/Index.vue"),
            },
            // {
            //     path: "/packages",
            //     name: "Packages",
            //     meta: {
            //         title: "Packages",
            //     },
            //     component: () => import("../views/website/pages/packages/Index.vue"),
            // },
            {
                path: "/companies",
                name: "Companies",
                meta: {
                    title: "Companies",
                },
                component: () => import("../views/website/pages/companies/Index.vue"),
            },
            {
                path: "/companies/:id",
                name: "Company-details",
                meta: {
                    title: "Company-details",
                },
                component: () => import("../views/website/pages/companies/id/Index.vue"),
            },
            {
                path: "/about",
                name: "about",
                meta: {
                    title: "about",
                },
                component: () => import("../views/website/pages/about/Index.vue"),
            },
            {
                path: "/contact",
                name: "contact",
                meta: {
                    title: "contact",
                },
                component: () => import("../views/website/pages/contact/Index.vue"),
            },
            {
                path: "/exhibitions",
                name: "exhibitions",
                meta: {
                    title: "exhibitions",
                },
                component: () => import("../views/website/pages/exhibitions/Index.vue"),
            },
            {
                path: "/categories",
                name: "categories",
                meta: {
                    title: "categories",
                },
                component: () => import("../views/website/pages/categories/Index.vue"),
            },
            {
                path: "/blogs",
                name: "blogs",
                meta: {
                    title: "blogs",
                },
                component: () => import("../views/website/pages/blogs/Index.vue"),
            },
            {
                path: "/blogs/:id",
                name: "blog-id",
                meta: {
                    title: "blog-id",
                },
                component: () => import("../views/website/pages/blogs/id/Index.vue"),
            },
            {
                path: "/services/:id",
                name: "service-id",
                meta: {
                    title: "service-id",
                },
                component: () => import("../views/website/pages/services/id/Index.vue"),
            },
            {
                path: "/requests",
                name: "requests",
                meta: {
                    title: "requests",
                },
                component: () => import("../views/website/pages/requests/Index.vue"),
            },
            {
                path: "/requests/:id",
                name: "request-id",
                meta: {
                    title: "request-id",
                },
                component: () => import("../views/website/pages/requests/id/Index.vue"),
            },
            {
                path: "/user/login",
                name: "User-Login",
                meta: { requiresNoAuth: true, title: "User-Login" },
                component: () => import("../views/website/pages/auth/login/Index.vue"),
            },
            {
                path: "/user/forget-password",
                name: "User-Forget Password",
                meta: { requiresNoAuth: true, title: "User-Forget-Password" },
                component: () => import("../views/website/pages/auth/forget-password/Index.vue"),
            },
            {
                path: "/user/reset-password",
                name: "User-Reset Password",
                meta: { requiresNoAuth: true, title: "User-Reset-Password" },
                component: () => import("../views/website/pages/auth/reset-password/Index.vue"),
            },
            {
                path: "/user/register",
                name: "User-Register",
                meta: { requiresNoAuth: true, title: "User-Register" },
        
                component: () => import("../views/website/pages/auth/register/Index.vue"),
            },
            {
                path: "/user/verify-email/:token",
                name: "User-verify-email",
                meta: { requiresNoAuth: true, title: "User-verify-email" },
        
                component: () => import("../views/website/pages/auth/verify-email/Index.vue"),
            },
            {
                path: "/user/register-company",
                name: "auth-register-company",
                meta: {
                    title: "auth-register-company",
                },
                component: () => import("../views/website/pages/auth/register-company/Index.vue"),
            },                
        ],
    }];

export default routes