import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    token: "",
    adminToken: "",
    role: "",
    userDetails: {},
    userViews: {},
    adminDetails: {},
    languages: [
      { name: "English", icon: "", value: "en" },
      { name: "Arabic", icon: "", value: "ar" },
      { name: "German", icon: "", value: "de" },
      { name: "Spanish", icon: "", value: "es" },
      { name: "French", icon: "", value: "fr" },
      { name: "Turkish", icon: "", value: "tr" },
      { name: "Russian", icon: "", value: "ru" },
    ],
    genders: [
      { title: 'MR', value: 'MR' },
      { title: 'MRS', value: 'MRS' },
    ],
    userStatusUpdated: false,
    loadedServices: false,
    servicesWebsite: [],
    loadedCategories: false,
    categoriesWebsite: []
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    token(state) {
      return state.token;
    },
    adminToken(state) {
      return state.token;
    },
    role(state) {
      return state.role;
    },
    userDetails(state) {
      return state.userDetails;
    },
    userViews(state) {
      return state.userViews;
    },
    userStatusUpdated(state) {
      return state.userStatusUpdated;
    },
    adminDetails(state) {
      return state.adminDetails;
    },
    languages(state) {
      return state.languages;
    },
    genders(state) {
      return state.genders;
    },
    loadedServices(state) {
      return state.loadedServices;
    },
    servicesWebsite(state) {
      return state.servicesWebsite;
    },
    loadedCategories(state) {
      return state.loadedCategories;
    },
    categoriesWebsite(state) {
      return state.categoriesWebsite;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    REMOVE_USER(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      state.token = "";
      state.role = "";
      state.userDetails = {};
      router.go("/");
    },
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload.userDetails?.user
      state.userViews = payload.userDetails
    },
    SET_USER_STATUS(state) {
      state.userStatusUpdated = !state.userStatusUpdated
    },
    SET_USER_TOKEN(state, payload) {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    REMOVE_ADMIN(state) {
      localStorage.removeItem("adminToken");
      state.adminToken = "";
      state.adminDetails = {};
      router.go("/admin");
    },
    SET_ADMIN_DETAILS(state, payload) {
      state.adminDetails = payload.adminDetails
    },
    SET_ADMIN_TOKEN(state, payload) {
      localStorage.setItem("adminToken", payload);
      state.token = payload;
    },
    SET_LOADED_DATA_SERVICES(state, payload) {
      state.loadedServices = payload.loaded;
      state.servicesWebsite = payload.data;
    },
    SET_LOADED_DATA_CATEGORIES(state, payload) {
      state.loadedCategories = payload.loaded;
      state.categoriesWebsite = payload.data;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    logoutAdmin({ commit }) {
      commit("REMOVE_ADMIN");
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setUserDetails({ commit }, payload) {
      commit("SET_USER_DETAILS", payload);
    },
    setUserStatusUpdate({ commit }) {
      commit("SET_USER_STATUS");
    },
    setUserToken({ commit }, payload) {
      commit("SET_USER_TOKEN", payload);
    },
    setAdminDetails({ commit }, payload) {
      commit("SET_ADMIN_DETAILS", payload);
    },
    setAdminToken({ commit }, payload) {
      commit("SET_ADMIN_TOKEN", payload);
    },
    setLoadedDataServices({ commit }, payload) {
      commit("SET_LOADED_DATA_SERVICES", payload);
    },
    setLoadedDataCategories({ commit }, payload) {
      commit("SET_LOADED_DATA_CATEGORIES", payload);
    },
    async translateHandler(_, payload) {
      console.log('payload', payload)
      let res = await axios.post('/translate', {
        text: payload.type == 'keywords' ? payload.text.join(",") : payload.text,
        "sourceLanguage": "en",
        "targetLanguage": payload.targetLanguage
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || localStorage.getItem("adminToken")}`,
        }
      })
      if (payload.type == 'keywords') {
        let resKeywords = []
        if (payload.targetLanguage == 'ar') {
          resKeywords = res.data.TranslatedText.split("،")
        } else {
          resKeywords = res.data.TranslatedText.split(",")
        }
        return resKeywords
      } else {
        return res.data.TranslatedText
      }
    }
  },
  modules: {},
});
